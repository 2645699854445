.sidebar{
	height: 100vh;
	width: 80px;
	position: fixed;
	box-shadow: inset -7px 9px 15px -3px rgba(52, 36, 87, 0.1);
	background-color: #f3f4f6;
}

.sidebar-icons{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	padding: 20px 0px;
	height: 100%;
}
