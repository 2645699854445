
html{
	background-color: #f3f4f6;
}
body{
	font-family: 'Poppins', sans-serif;
	background-color: #f3f4f6;
}

/** Scroll Bar **/

body {
  --sb-track-color: #dfd0ff;
  --sb-thumb-color: #5b438f;
  --sb-size: 12px;

  scrollbar-color: var(--sb-thumb-color) 
                   var(--sb-track-color);
}

body::-webkit-scrollbar {
  width: var(--sb-size) 
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
  border: 2px solid #dfd0ff;
}





.theme-scroll-bar {
  --sb-track-color: #dfd0ff;
  --sb-thumb-color: #5b438f;
  --sb-size: 12px;

  scrollbar-color: var(--sb-thumb-color) 
                   var(--sb-track-color);
}

.theme-scroll-bar::-webkit-scrollbar {
  width: var(--sb-size) 
}

.theme-scroll-bar::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

.theme-scroll-bar::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;
  border: 2px solid #dfd0ff;
 }




a{
	text-decoration: none;
}

.Root{
	background-color: #f3f4f6;
}
.theme-full-page{
	height: 100vh;
	width: 100vw;
	margin: 0px;
}
.theme-full-height{
	height: 100vh;
}
.theme-full-width{
	width: 100%;
}







/** initial set, this is mostly override by other class **/
.material-symbols-outlined{ color: #595959; }
.theme-indevelopment-text{ font-weight: bold; font-size: 2.5rem; color: #5b438f;}


.text-black{
	color: #000;
}


/** Theme Colors **/
.theme-banner-bg{ background-color: #5b438f; }

.theme-color{ color: #f6f2ff; }
.theme-color.dark{ color: #342457; }
.theme-color.warning{ color: #a83232 }
.theme-color.success{ color: #32a862 }

.theme-bg{ background-color: #f6f2ff }
.theme-bg.dark{ background-color: #342457 }


.theme-bg-warning{
	background-color: #a83232;
}
.theme-bg-success{
	background-color: #32a862;
}






/** Developement Related **/
.theme-indevelopment-illustration{ width: 80%; }


.status-display{
	width: 100%;
	color: #fff;
	padding: 1rem 2rem;
	font-weight: bold;
}
.status-display.success{ background-color: #00d94c; }
.status-display.error{ background-color: #ff0040; }


.panel-topbar{
	padding: 2px 10px;
	box-shadow: 0px 10px 15px -3px rgba(52, 36, 87, 0.04);
	background-color: #5b438f;
}





.theme-lift{
	box-shadow: 0px 10px 15px -3px rgba(52, 36, 87, 0.1);
}
.theme-btn{
	padding: 0.5rem 2rem;
	font-size: 0.8rem;
	font-weight: bold;
	border: none;
	border-radius: 8px;
}
.theme-btn-primary{
	background-color: #006aff;
	color: #fff;
}





/** FONT STANDARD SIZES **/
.theme-text-xsm{ font-size: 0.6rem }
.theme-text-sm{ font-size: 0.8rem }
.theme-text-nm{ font-size: 1rem }
.theme-text-md{ font-size: 1.4rem }
.theme-text-lg{ font-size: 1.8rem }
.theme-text-xlg{ font-size: 2rem }




/** Headers */
.theme-header{
	font-size: 1.2rem;
	font-weight: bold;
	padding: 1rem 0rem;
	color: #8700b0;
}

.theme-header.header-sm{font-size: 1.2rem;}
.theme-header.header-nm{font-size: 1.4rem;}
.theme-header.header-md{font-size: 1.6rem;}
.theme-header.header-lg{font-size: 1.8rem;}
.theme-header.header-xl{font-size: 2rem;}






/** Section Titles **/
.theme-section-title{
	margin: 0px;
	color: #8700b0;
	font-size: 1.6rem;
	font-weight: bold;
	padding: 0rem 1rem;
	border-left: 8px solid #8700b0;
}
.theme-section-title.title-lg{ font-size: 1.6rem; }
.theme-section-title.title-md{ font-size: 1.4rem; }
.theme-section-title.title-nm{ font-size: 1rem; }

.theme-section-title.title-light{ color: #f6f2ff; border-left: 8px solid #f6f2ff;}
.theme-section-title.title-dark{ color: #342457; border-left: 8px solid #342457;}







/** icon buttons **/
.theme-iconed-btn{
	background-color: #5b438f;
	color: #f6f2ff;
	border: none;
	padding: 4px 1rem;
	font-size: 0.85rem;
	margin: 0px 2px;
	padding: 10px 0px;
	border-radius: 4px;
}

.theme-iconed-btn.btn-sm{ width: 10rem; font-size: 0.75rem; }
.theme-iconed-btn.btn-md{ width: 14rem; font-size: 0.85rem; }
.theme-iconed-btn.btn-lg{ width: 18rem; font-size: 0.95rem; }










/** cards **/
.theme-cardify{
	padding: 25px;
	box-shadow: 1px 3px 9px -3px rgba(0,0,0,0.1);
	border-radius: 8px;
	width: 380px;
	background-color: #fff;
}

.theme-cardify.card-expand{ width: 100%; height: 100%;}
.theme-cardify.card-w-expand{ width: 100%; }
.theme-cardify.card-sm{ width: 180px; }
.theme-cardify.card-nm{ width: 320px; }
.theme-cardify.card-md{ width: 380px; min-width: 320px}
.theme-cardify.card-lg{ width: 580px; }
.theme-cardify.card-xlg{ width: 680px; }








/** round corner **/
.rounded-corner{
	border-radius: 8px;
}








/** buttons **/
.theme-btn-no-style{
	border: none;
	padding: 0px;
	margin: 0px;
	background-color: rgba(0, 0, 0, 0);
}


.theme-btn{ 
	background-color: #5b438f;
	color: #f6f2ff;
	border: 1px solid #5b438f;
	padding: 4px 1rem;
	font-size: 0.85rem;
	margin: 0px 2px;
	padding: 8px 0px;
	border-radius: 4px; 
	}

.theme-btn:hover{
	background-color: #f6f2ff;
	color: #5b438f;
	border: 1px solid #5b438f;
}


.theme-btn.theme-btn-secondary{
	color: #f6f2ff;
	background-color: #9a88c2;
	border: 1px solid #5b438f;
}

.theme-btn.theme-btn-secondary:hover{
	background-color: #f6f2ff;
	color: #5b438f;
	border: 1px solid #5b438f;
}


.theme-btn.theme-btn-warning{
	color: #f6f2ff;
	background-color: #d53636;
	border: 1px solid #d53636;
}

.theme-btn.theme-btn-warning:hover{
	color: #d53636;
	background-color: #fff2f2;
	border: 1px solid #d53636;
}


.theme-btn.btn-expand{ width: 100%; font-size: 0.9rem; }
.theme-btn.btn-xsm{ width: 6rem; font-size: 0.75rem; }
.theme-btn.btn-sm{ width: 10rem; font-size: 0.75rem; }
.theme-btn.btn-md{ width: 14rem; font-size: 0.85rem; }
.theme-btn.btn-lg{ width: 18rem; font-size: 0.95rem; }
.theme-btn.btn-fluid{ padding: 0.5rem 1rem; font-size: 0.95rem; }








/** INPUTS **/
.form-control.theme-input-field{ 
	border-top: none;
	border-left: none;
	border-bottom: 1px solid #342457;
	border-right: none;
	border-radius: 0px;
}

.theme-label-sm{
	font-size: 0.7rem;
}






/** MAterial  Icons **/
.material-icons.icon-sm { font-size: 18px;}
.material-icons.theme-panel-icon-menu { font-size: 40px; }
.material-icons.theme-panel-icon-user { font-size: 32px; }

.theme-no-margin{margin: 0px;}
.theme-no-padding{padding: 0px;}








/** BUTTON WITH ICONS VERICAL LAYOUT **/ 
.IconedButtonV{
	max-width: 77px;
	min-width: 77px;
	text-align: center;
	padding: 10px 0px;
}
.IconedButtonV:hover{ background-color: #ffffff;}


.iconed_btn_label{
	font-size: 0.6rem;
	line-height: 0.1;
}

.IconedButtonV.no-padding{ padding: 0px; }

.IconedButtonV.iconed-btn-auto{width: 100%;}
.IconedButtonV.iconed-btn-sm{max-width: 77px; min-width: 77px;}
.IconedButtonV.iconed-btn-md{max-width: 88px; min-width: 88px;}
.IconedButtonV.iconed-btn-lg{max-width: 120px; min-width: 120px;}
.IconedButtonV.iconed-btn-xlg{max-width: 110px; min-width: 110px;}

.iconed_btn_label.label-sm{font-size: 0.6rem;}
.iconed_btn_label.label-md{font-size: 0.7rem;}
.iconed_btn_label.label-lg{font-size: 0.9rem;}
.iconed_btn_label.label-xlg{font-size: 1rem;}









/** BUTTON WITH ICONS HORIZONTAL LAYOUT **/ \
.icon-h-btn{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}


.IconedButtonV.margined-side{
	margin: 0px 5px;
}








/** MODAL **/
.theme-modal-container{
	background-color: #fff;
	border-radius: 8px;
	max-height: 90vh;
	overflow: hidden;
}


.theme-modal-container.theme-modal-sm{ width: 30vw }
.theme-modal-container.theme-modal-md{ width: 60vw }
.theme-modal-container.theme-modal-lg{ width: 80vw }
.theme-modal-container.theme-modal-full{ width: 100vw }

.theme-modal-header{ 
	padding-bottom: 8px;
	margin-bottom: 12px;
	border-bottom: 0.5px solid rgba(59, 8, 69, 0.5);
}
.theme-modal-body{
	max-height: 75vh;
	overflow-y: scroll;
	overflow-x: hidden;
}
.theme-modal-footer{ 
	padding-top: 8px;
	margin-top: 12px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
}






/** Images **/
.profile_image_container{
	height: 180px;
	width: 180px;
}

.profile_image{
	width: 100%;

}







/** SVG Illustrations**/


.theme-illustratrion-md{
	width: 25%;
}



/** ProfileSearchResultCard CSS **/
.cont-expand-w{
	width: 100%;
}
.result_card_image_container{
	height: 140px;
	width: 140px;
	border-radius: 4px;
}

/** Image **/
.image_fluid{
	width: 100%;
	height: 100%;

}






/***** Profile Page Styles *****/
#profile_image_container{
	height: 140px;
	width: 140px;
	border-radius: 50%;
}
#profile_image{
	height: 100%;
	width: 100%;
	border-radius: 6px;
}
